import Box from "@mui/material/Box";
import React from "react";
import LoadingSVG from './admin/images/loadingimage.png';
import './loading.css';
import {
  Typography,
  FormControl,
  TextareaAutosize,
  Dialog,
  DialogActions,
  InputLabel,
  DialogContent,
  Grid,
  Button,
  OutlinedInput,
} from "@mui/material";

const Loading = () => {
  return (
    <Box
      className="loader-back"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",

      }}
    >
      <div style={{
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
      }}>

        <span class="loader1"></span>
        <Typography
          sx={{
            fontSize: "50px",
            background: "-webkit-linear-gradient(#000, #edc326)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent"
          }}
        >
          Anubhuthi AI
        </Typography>
      </div>

    </Box>
  );
};

export default Loading;